import React,{Fragment,useState,useEffect} from 'react';
import ScheduleCell from './ScheduleCell.js';
import ScheduleTableDataRow from './ScheduleTableDataRow.js';
import DetailsModal from './detailsModal.js';
import SuccessfullySentModal from './SuccessfullySentModal.js';
import styles from '../scss/scheduleTableData.module.scss';
import Axios from "axios";


const ScheduleTableData = () =>
{
  //for common modal
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //for relavent schedule data
  const [hour, setHour] = useState(0);
  const [day, setDay] = useState(0);
  const handleHour = (data) => setHour(data);
  const handleDay = (data) => setDay(data);

  //for SuccessfullySentModal
  const [isSuccessfullySent, setSuccessfulltSent] = useState(false);
  const handleCloseSuccessfullySent = () => setSuccessfulltSent(false);
  const handleOpenSuccessfullySent = () => setSuccessfulltSent(true);

  const [dbData, updateDB] = useState([]);
  const handleDB = (data) => updateDB(data);

  var domain=``   //`http://lidormarkovich.co.il/api`    //`http://localhost:3001/api`
  //if we are in production
  // if(process.env.NODE_ENV==='production')
  //   domain = `/api`  //cause its going to be on the same machine
  // else
  //test
  //const host=window.location.host
  //console.log(host);
  domain = `https://tutoringserver.herokuapp.com/api` //`http://localhost:4000/api` //`http://52.23.230.157/api` // `http://localhost:3001/api` //api
  //if we are in development



  const dbColumnNames=['hour9','hour10','hour11','hour12','hour13','hour14','hour15','hour16','hour17','hour18','hour19','hour20',];

//const test=`http://`+host+`/api/`

  useEffect( () =>
  {
      const weekSchedule=async() =>
      {
  console.log("in weekSchedule use effect ");
         let output=[];
         try
         {
           const res = await Axios.get(domain+"/getWeekSchedule",{
              headers: {
                "x-apikey": "API_KEY",
              },
              responseType: "json",
            }).catch(function (error) {
           //const res = await Axios.get(test).catch(function (error) {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              }
            });
  console.log("use effect result of res == "+res.data);
    var currentDayIndex = new Date().getDay();
           // const output = await JSON.stringify(res);
            for (var i = 0; i < (res.data).length; i++)
            {
                if(i<currentDayIndex)
                  output[i]=[];
                else
                  output[i]=Object.values(res.data[i]);
            }
            handleDB(output);
            console.log("outputtt"+output[0][0]);
          }
         catch (error)
         {
          console.error("error from server "+error);
         }
      }
      weekSchedule()
   },[]);

  const rows = [];
  const buildRowsArray = ()=>
  {
    //var dbResult=getWeekSchedule()
  console.log("lulaaa data from server: "+dbData);
      for(let i = 1; i < 12; i++)
      {
        //build an array with values that represents the row schedule
        var arr=[];
        for (var j = 0; j < dbData.length; j++)
        {
           arr[j]=dbData[j][i];    //Object.keys(dbData[j])[0]
        }
  console.log("row   "+arr);
        //schedule={arr}
        rows[i] = <ScheduleTableDataRow handleClicked={modalClicked} hour={9+i} scheduleData={arr}  />
      }
  }

  const modalClicked=(chosenHour,chosenDay) =>
  {
    if(true)//if the cell is avialable the modal is invoke
    {
      handleShow();
      setHour(chosenHour);
      setDay(chosenDay);
    }
    // Axios.post("http://localhost:3001/addRequest",{date:'1/3/1996',phone:'711131111',firstName:'fromFrontRequest',lastName:'blank',mail:'requestMail',subject:'blank'}).catch(function(error) {
    //     console.log("pppppppp"+error);
    //   });
  }
  //after receving the user input from the modal
  const updateRequestDataToDb=(fName,lName,phone,mail,theSubject)=>
  {
    //inserting the data to student table
    Axios.post(domain+"/addStudent",{firstName:fName,lastName:lName,phone:phone,mail:mail}).catch(function(error)
    {
       console.log("pppppppp"+error);
    });
    //inserting the data to request table
    let c=getCurrent();
    let d=getDesired();
    Axios.post(domain+"/addRequest",{subject:theSubject,firstName:fName,lastName:lName,phone:phone,current:c ,desired:d}).catch(function(error)
    {
       console.log("pppppppp"+error);
    });
    handleClose(); //closing modal after handling the details
    handleOpenSuccessfullySent();
  }

  function getCurrent()
  {
      var today = new Date();
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+" 00:00:00";
      return date;
  }
  function getDesired()
  {
      var today = new Date();
      var desired=new Date();
      desired.setDate(today.getDate()+(day-today.getDay()));
      var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+desired.getDate()+" "+hour+":00:00";
      return date;
  }

  return(
    <div>
      {buildRowsArray()}
      {rows}
      <DetailsModal enable={show} day={day} hour={hour} handleClicked={updateRequestDataToDb} closeModal={handleClose}/>
      <SuccessfullySentModal enable={isSuccessfullySent} closeModal={handleCloseSuccessfullySent}/>
    </div>
  );
}
export default ScheduleTableData;
