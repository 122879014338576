import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
	  BrowserRouter as Router,
	  Switch,
	  Route
	} from "react-router-dom";
import ContactMe from './components/ContactMe.js'
import Pricing from './components/Pricing.js'
import Schedule from './components/Schedule.js'

ReactDOM.render(
  <Router>
      <Switch>
				<Route exact path="/pricing" component={Pricing} />
				<Route exact path="/contactMe" component={ContactMe} />
        <Route exact path="/" component={() => <App />} />
				<Route exact path="/Schedule" component={Schedule} />
       </Switch>
  </Router>,
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  document.getElementById('root')
);
