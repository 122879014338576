import React, { useState } from 'react';
import  { Component} from "react";
import styles from '../scss/scheduleStyle.module.scss';
import rootStyles from '../scss/appStyle.module.scss';
import ScheduleHeader from './ScheduleHeader.js';
import ScheduleTableData from './ScheduleTableData.js';


const title=<div className="row" dir="rtl"><div className="col-lg-12 col-md-12 col-xs-12"><h1 className={styles.titleOfSchedule} id={rootStyles.title}> מערכת לקביעת שיעור </h1></div></div>;

const Schedule = () =>
{
  return (
    <div id={styles.schedule} >
      <div className="container" id={styles.myContainer}>
        {title}
        <ScheduleHeader />
        <ScheduleTableData />
      </div>
    </div>
  )
}

export default Schedule;



// const Schedule extends Component
// {
//   const [Amazing,setAmazing] = useState('');
//
//   // function haha(){
//   //   setAmazing();
//   // }
//
//   render()
//   {
//     return(
//           <div  id={styles.schedule} >
//             <div className="container" id={styles.myContainer}>
//               <div className="row" dir="rtl">
//                 <div className="col-lg-12 col-md-12 col-xs-12">
//                 <scheduleCell />
//                   <h1 className={styles.titleOfSchedule} id={rootStyles.title}> מערכת לקביעת שיעור </h1>
//                 </div>
//               </div>
//               <div id={styles.scheduleTable}>
//                 <div id={styles.tableHeader}>
//                   <p className={styles.cell}> יום ראשון 3.1 </p>
//                   <p className={styles.cell}> יום ראשון 3.1 </p>
//                   <p className={styles.cell}> יום ראשון 3.1 </p>
//                   <p className={styles.cell}> יום ראשון 3.1 </p>
//                   <p className={styles.cell}> יום ראשון 3.1 </p>
//                   <p className={styles.cell}> יום ראשון 3.1 </p>
//                   <p className={styles.cell}> יום ראשון 3.1 </p>
//                   <div onClick={haha}> {Amazing}</div>
//                 </div>
//                 <div id={styles.tableData}>
//                     <div id={styles.tableRow}>
//                       <ScheduleCell hour={getHours()} />
//                       <ScheduleCell hour={getHours()} />
//                       <ScheduleCell hour={getHours()} />
//                       <ScheduleCell hour={getHours()} />
//                       <ScheduleCell hour={getHours()} />
//                       <ScheduleCell hour={getHours()} />
//                       <ScheduleCell hour={getHours()} />
//                     </div>
//                     פה יהיו עוד צריכים לשכפל את עצמם גם בימים צריכים לשכפל את עצמם לפני התארי ך של היום
//                 </div>
//               </div>
//             </div>
//           </div>
//         );
//       }
// }
