import styles from '../scss/scheduleCell.module.scss';

const ScheduleCell = (props) =>
{
    //calls grandfather method with the rellavent params
    const callToHandleClicked=()=>
    {
        //check whether the cell is clickable
        if(props.available )
          props.handleClicked(props.hour,props.dayIndex);
    }
    return (
      <div id={styles.ScheduleCell}>
        <p onClick={callToHandleClicked} id={styles.ScheduleCellContent} className={props.available ? styles.available : styles.notAvailable}> {props.hour}:00 </p>
      </div>
    );
}

export default ScheduleCell;
