import  { Component } from "react";
import styles from '../scss/contactForm.module.scss';
import rootStyles from '../scss/appStyle.module.scss';
import ReactTypingEffect from 'react-typing-effect';
import emailjs from 'emailjs-com';
import 'reactjs-popup/dist/index.css';

const Mailer = () =>
{
  // When the user clicks anywhere outside of the modal, close it
  window.onclick = function(event)
  {
    var modal = document.getElementById("myModal");
    if (event.target == modal)
    {
      modal.style.display = "none";
    }
  }
  function sendEmail(e)
  {
      e.preventDefault();
      if( (document.getElementById('phone').value).toString().length>=10)
      {
        if(document.getElementsByClassName('subject')[0].value=='default')
            alert("נא לבחור נושא");
        else
        {
            emailjs.sendForm('service_70h3ulo',`template_8acpkyb`,e.target,"user_O88xbYJ21DHITTjYjXdyy").then(
            res=>
            {
              var modal = document.getElementById("myModal");
              if(modal)
                modal.style.display = "block";
              document.getElementById('userName').value='';
              document.getElementById('phone').value='';
              document.getElementsByClassName('subject')[0].value='default';
            }).catch(err=> {alert("אופס לא הצלחנו לקבל את הפרטים שלך"); console.log(err);});
        }
     }
     else
        alert("מספר נייד לא תקין !");
  }
  function spanClicked(e)
  {
    var modal = document.getElementById("myModal");
    e.preventDefault();
    if(modal)
      modal.style.display = "none";
  }
  return(
    <div className={styles.container}>
      <form onSubmit={sendEmail} dir="rtl">
        <h4 id={rootStyles.title}>השארת פרטים לחזרה</h4>
        <input required id="userName" type="text" name="name" placeholder="שם" /><br />
        <select required defaultValue={'default'} className="subject" id={styles.subject} name="selectedSubject">
          <option value="default" disabled >בחר נושא שיעור</option>
          <option value="אוטומטים ושפות פורמליות">אוטומטים ושפות פורמליות</option>
          <option value="הכנה לבגרות במדעי המחשב">הכנה לבגרות במדעי המחשב</option>
          <option value="מבוא למדמח בשפת JAVA">מבוא למדמח בשפת JAVA</option>
          <option value="תכנות מתקדם בשפת JAVA">תכנות מתקדם בשפת JAVA</option>
          <option value="מבני נתונים">מבני נתונים</option>
          <option value="אלגוריתמים">אלגוריתמים</option>
          <option value="html css js">html css js</option>
          <option value="אחר">אחר</option>
        </select><br />
        <input required id="phone" type="text" name="phone" placeholder="מספר נייד" /><br />
        <input type="submit" value="שלח" />

        <div id="myModal" className={styles.modal}>
          <div className={styles.modalContent}>
            <span onClick={spanClicked} className={styles.close}>&times;</span>
            <p dir="rtl">הפרטים התקבלו, נהיה בקשר בקרוב!</p>
          </div>
        </div>

      </form>
    </div>
  );
};
export default Mailer;
