import styles from '../scss/scheduleStyle.module.scss';

const Subject = (props) =>{
  return (
    <div  dir="rtl" id={styles.Subject} >
          <i title= {props.data} class="far fa-hand-point-left"></i>
          {props.data}
    </div>
  );
}

export default Subject;
