import React,{useState} from 'react';
import styles from '../scss/detailsModal.module.scss';
import contactForm from '../scss/contactForm.module.scss';

const SuccessfullySentModal = (props) =>
{
    return (
      <div id={styles.modal} className={props.enable ? styles.enable : styles.disable}>
        <div id={styles.content}>
          <div id={styles.closeModal} onClick={props.closeModal}>X</div>
          <p dir="rtl" className={styles.modalTitle}> הבקשה התקבלה בהצלחה ! </p>
          <p className={styles.modalTitle}> לאחר אישור הבקשה ישלח אליך זימון לשיעור שלנו בזום </p>
          <form name="myForm" onSubmit={props.closeModal} >
            <button type='submit'>אישור</button>
          </form>
        </div>
      </div>
    );
}

export default SuccessfullySentModal;
