import React,{useState} from 'react';
import styles from '../scss/detailsModal.module.scss';
import contactForm from '../scss/contactForm.module.scss';
import emailjs from 'emailjs-com';

const DetailsModal = (props) =>
{
    const [fName, setFName] = useState("");
    const handleFName = (data) => setFName(data);
    const [lName, setLName] = useState("");
    const handleLName = (data) => setLName(data);
    const [phone, setPhone] = useState("");
    const handlePhone = (data) => setPhone(data);
    const [mail, setMail] = useState("");
    const handleMail = (data) => setMail(data);
    const [subject, setSubject] = useState("");
    const handleSubject = (data) => setSubject(data);

    const [subjectFieldError, setSubjectFieldError] = useState("");
    const handleSubjectFieldError= (data) => setSubjectFieldError(data);
    const [phoneFieldError, setPhoneFieldError] = useState("");
    const handlePhoneFieldError= (data) => setPhoneFieldError(data);

    const days=['ראשון','שני','שלישי','רביעי','חמישי','שישי','שבת'];

    const prepareForSendingToFather=(event)=>
    {
      var templateParams = {
        first_name: document.getElementsByName("first name")[0].value ,
        last_name: document.getElementsByName("last name")[0].value ,
        phone: document.getElementById("userPhone").value ,
        selectedSubject: document.getElementsByClassName("chosenSubject")[0].selectedOptions[0].value ,
        date: document.getElementById("date").innerText
      };

      //sending email notification
      //event.target
      emailjs.send('service_70h3ulo',`template_ruyrwk5`,templateParams,"user_O88xbYJ21DHITTjYjXdyy");

      event.preventDefault();
      var chosenSubject=document.getElementsByClassName('chosenSubject')[0];
      if(chosenSubject.value=='default')
            handleSubjectFieldError(true);
      else
      {
        let fixedPhone=fixPhone(phone);

        if(fixedPhone==undefined)
            handlePhoneFieldError(true);
        else
        {
            handleSubjectFieldError(false);
            handlePhoneFieldError(false);
            props.handleClicked(fName,lName,fixedPhone,mail,subject);
        }

      }
    }
    function fixPhone(data)
    {
        if(data.length == 13 && data.substring(0,4)=="+972")
          return 0+""+data.substring(4);
        if(data.length == 10 && !(data.substring(0,4)=="+972"))
          return data;
    }

    return (
      <div id={styles.modal} className={props.enable ? styles.enable : styles.disable}>
        <div id={styles.content}>
          <div id={styles.closeModal} onClick={props.closeModal}>X</div>
          <p id="date" className={styles.modalTitle}>בקשה לקביעת שיעור ביום {days[props.day]}  בשעה {props.hour}:00</p>

          <form name="myForm" onSubmit={prepareForSendingToFather} >
              <input required type="text" name="first name" placeholder="שם פרטי" onChange={evt => handleFName(evt.target.value)} />
              <input type="text" name="last name" placeholder="שם משפחה" onChange={evt => handleLName(evt.target.value)} />
              <input id="userPhone" required type="phone" name="phone" placeholder="מספר נייד לחזרה" onChange={evt => handlePhone(evt.target.value)} />
              <input type="email" name="email" placeholder="מייל" onChange={evt => handleMail(evt.target.value)} />
              <select  name="selectedSubject" required="true" defaultValue={"default"} id={contactForm.subject} className="chosenSubject" onChange={evt => handleSubject(evt.target.value)}>
                <option dir="rtl" value="default" disabled>בחר נושא שיעור</option>
                <option dir="rtl" value="מבוא למדמח בשפת JAVA">הכנה לבגרות העיונית במדעי המחשב</option>
                <option dir="rtl" value="מבוא למדמח בשפת JAVA">מבוא למדמח בשפת JAVA</option>
                <option dir="rtl" value="מבני נתונים">מבני נתונים</option>
                <option dir="rtl" value="מבני נתונים ומבוא לאלגוריתמים">מבני נתונים ומבוא לאלגוריתמים</option>
                <option dir="rtl" value="html css js">html css js</option>
              </select>
              <p dir="rtl" className={subjectFieldError ? styles.enable : styles.disable}> אופס לא בחרת נושא !</p>
              <p dir="rtl" className={phoneFieldError ? styles.enable : styles.disable}> מספר לא תקין !</p>
              <button type='submit'>שלח</button>
          </form>
        </div>
      </div>
    );
}

export default DetailsModal;
