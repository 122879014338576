import React from 'react';
import DayHeader from './DayHeader.js';

import styles from '../scss/ScheduleHeader.module.scss';

  const ScheduleHeader = () =>
  {
      const days = [];
      const build = ()=>
      {
        for(let i = 0; i < 7; i++)
          days[i] = <DayHeader day={6-i}/>
      }

      return (
        <div id={styles.tableHeader}>
          {build()}
          {days}
        </div>
      );
  }

export default ScheduleHeader;
