import React,{Fragment,useState} from 'react';
import ScheduleCell from './ScheduleCell.js';
import styles from '../scss/scheduleTableData.module.scss';
import Axios from "axios";


const ScheduleTableDataRow = (props) =>
{
  const [dataFramServer, set] = useState(true);
  const handleSetTrue = () => set(true);
  const handleSetFalse = () => set(false);


  const cells=[];
  const buildCells = ()=>
  {
      for(let i = 0; i < 7; i++)
      {
          var curr = new Date(); //date of first column
          var add=i-curr.getDay();
          var cellDate=new Date();
          cellDate.setDate(curr.getDate()+add);
          var fullDate=cellDate.getFullYear()+'-'+(cellDate.getMonth()+1)+'-'+(cellDate.getDate());
          //console.log('cell date: '+fullDate);
          //var rowIndex=props.hour-9;

          //isCellAvailable(fullDate,props.hour);{props.scheduleData[i]}
         cells[i] = <ScheduleCell hour={props.hour} dayIndex={i} handleClicked={props.handleClicked} available={props.scheduleData[i]} />
      }
  }

  return(
    <div dir="rtl" id={styles.tableRow}>
      {buildCells()}
      {cells}
    </div>
  );
}
export default ScheduleTableDataRow;
