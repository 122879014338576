import  { Component } from "react";
import Mailer from './ContactForm.js'
import styles from '../scss/contactMe.module.scss';
import rootStyles from '../scss/appStyle.module.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

import fadeIn from 'react-animations/lib/fade-in'
import { bounce } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

class ContactMe extends Component
{
  render()
  {
    return (
            <div id={styles.ContactMe}>
              <StyleRoot>
                <div id={styles.content} className="content">
                    <div dir="rtl" id={styles.whatsapp} >
                      <p dir="rtl" id={rootStyles.title}>יצירת קשר דרך הווצאפ </p>
                      <a href="https://wa.link/u041xy" target="_blank"> <i id={styles.whatsappIcon} className="fab fa-whatsapp"></i> </a>
                    </div>
                    <p  className={styles.alignRight} id={rootStyles.title}> או</p>
                    <Mailer />
                </div>
              </StyleRoot>
            </div>
      );
  }
}

export default ContactMe;
