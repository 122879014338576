import  { Component } from "react";
import styles from '../scss/pricing.module.scss';
import rootStyles from '../scss/appStyle.module.scss';

class Pricing extends Component
{
  render()
  {
    return (
          <div  id={styles.pricing} >
            <div id={styles.content}>
              <h1 dir="rtl" id={rootStyles.title}> עלויות </h1>

              <div dir="rtl" className={styles.section}>
                <p dir="rtl"> שיעור פרטני מקוון (שעה עגולה) -  </p>
                <p className={styles.thePrice} dir="rtl">  130 ש"ח   </p>
              </div>
              <div dir="rtl" className={styles.section}>
                <p dir="rtl"> שיעור קבוצתי מקוון (שעה עגולה) -  </p>
                <p className={styles.thePrice} dir="rtl">65 ש"ח למשתתף  </p>
              </div>

            </div>
          </div>
      );
  }
}

export default Pricing;
