import  { Component } from "react";
import styles from '../scss/mainStyle.module.scss';
import ReactTypingEffect from 'react-typing-effect';

class BottomQuete extends Component
{
  render()
  {
    return (
            <div id={styles.bottomQuote} >
              <ReactTypingEffect dir="ltr"
                 text={"First,solve the problem.Then,write the code."}
                 eraseSpeed={1}
                 cursorRenderer={cursor => <h1>{cursor}</h1>}
                 displayTextRenderer={(text, i) => {
                   return (
                     <h1>
                         {text.split("\n").map((i,key) => {
                              return <div key={key}>{i}</div>;
                          })}
                       </h1>
                     );
                   }}
                />
            </div>
      );
  }
}

export default BottomQuete;
