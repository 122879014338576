import React,{Fragment} from 'react';

import styles from '../scss/scheduleStyle.module.scss';

const DayHeader = ( props ) => {

  const days=['ראשון','שני','שלישי','רביעי','חמישי','שישי','שבת'];
  //let tableHeader='';
  function getCellDate() //צריך לתקן למה התאריכים מוקפצים פה
  {
    var curr = new Date();
    var add=props.day-curr.getDay();
    curr.setDate(curr.getDate() + add);
    return " "+curr.getDate()+"."+(curr.getMonth()+1); //note to myself that month starts at 0
  }
  console.log(getCellDate());
  return (
    <div className={styles.cell}>
      {days[props.day]}
      <br />
      {getCellDate()}
    </div>
  );
};

export default DayHeader;
