import styles from './scss/appStyle.module.scss';
import Main from './components/Main.js'
import Schedule from './components/Schedule.js'
import MyNav from './components/MyNav.js';
import Pricing from './components/Pricing.js';
import ContactMe from './components/ContactMe.js';

function App() {
  const n="light";
  const n2="dark";
  return (
    <div id={styles.App}>

        <header>
          <MyNav figureColor={n2} />
        </header>

        <Main />

        <div className={styles.secondPage}>
          <div id="pricing">
            <Pricing />
            <MyNav figureColor={n}/>

          </div>
          <div id="contactMe">
            <ContactMe />
          </div>
        </div>

        <div id="schedule">
          <Schedule />
        </div>

        <footer>
        </footer>
    </div>
  );
}

export default App;
