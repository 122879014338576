import  { Component } from "react";
import styles from '../scss/mainStyle.module.scss';
import MovingFigure from './MovingFigure.js'
import fadeIn from 'react-animations/lib/fade-in'
import { bounce } from 'react-animations';
import Radium, {StyleRoot} from 'radium';

class MyNav extends Component
{
  constructor(props)
  {
    super(props);
    this.state = {color:"dark"};
    this.handleScroll = this.handleScroll.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  componentDidMount(){
      window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event)
  {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight-10)
        this.setState({color: "light"});
    else
        this.setState({color: "dark"});
  }
  handleOnClick()
  {
    this.setState({color: "light"});
  }

  render()
  {
    let NavColor=this.state.color;
    return (
          <div id={styles.nav}  >
              <br/>
              <MovingFigure figureColor={NavColor} text="קביעת שיעור" />
              <MovingFigure figureColor={NavColor}  text="יצירת קשר" />
              <MovingFigure figureColor={NavColor}  text="עלויות" />
``         </div>
      );
  }
}

export default MyNav;
