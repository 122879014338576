import  { Component } from "react";
import styles from '../scss/movingFigureStyle.module.scss';
import { useHistory } from "react-router-dom";

class MovingFigure extends Component
{

  constructor(props)
  {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnClick= (e) =>
  {
    e.preventDefault();
    // if(this.props.onClick==false)
    //   this.props.handleOnClick();
    var viewportHeight = window.innerHeight;
    var isFourPage=0,second=viewportHeight,third=viewportHeight*2,fourth=viewportHeight*3;
    if(window.innerWidth<1017)//in that case we get 4 pages
      isFourPage=1;
     if(this.props.text=="עלויות")
        window.scrollTo(0,second );
     if(this.props.text=="יצירת קשר")
     {
         if(isFourPage==1)
            window.scrollTo(0,third);
        else
            window.scrollTo(0,second);
     }
     if(this.props.text=="קביעת שיעור")
      {
        if(isFourPage==1)
           window.scrollTo(0, fourth);
        else
           window.scrollTo(0,third );
      }
    //   window.location.href='#contactMe';
    //   window.location.href='#pricing';
    // this.props.history.push('/Schedule');
    //     //window.location.href='#schedule';
    // window.scrollTo(0, 0); //first page
    // window.scrollTo(0, 568); //second page
    // window.scrollTo(0, 1200); //third page
  }
  render()
  {
    const color=this.props.figureColor;
    return(
      <figure onClick={this.handleOnClick} className={ [styles.swing, styles[color] ].join(' ') }>
        <button>{this.props.text}</button>
      </figure>
    );
  }
}
export default MovingFigure;
