import  { Component } from "react";
import styles from '../scss/mainStyle.module.scss';
import rootStyles from '../scss/appStyle.module.scss';
import BottomQuete from './BottomQuete.js'
import me from '../images/me.jpg';
import Subject from './Subject.js';

const title=<div dir="rtl" class="row"><div id={styles.m} class="col-md-12"><img id ={styles.test} src={me} alt="מורה פרטית לגאווה sql tutoring java" /></div><div class="col-md-12"><p dir="rtl" id={rootStyles.title}> מורה פרטית - לידור מרקוביץ'  </p></div></div>;
//const aboutMe=<div className="row"><div class="col"><p dir="rtl"> בעלת ניסיון רב בהוראה בתחום מדעי המחשב </p><p dir="rtl">מעבירה שיעורים פרטיים ליחידים / לקבוצות בנושאים הבאים:</p></div></div>;
const aboutMe=<div className="row">
                <div class="col">
                    <p dir="rtl"> במהלך לימודיי לתואר הראשון פיתחתי גישת הוראה מקורית להעברת חומר הלימוד. </p>
                    <p dir="rtl">אני יודעת כמה החומר יכול להרגיש סינית ברגע שלומדים בצורה לא נכונה ובשביל זה אני כאן </p>
                    <p dir="rtl"> באילו נושאים אני מתמחה ? </p>
                </div>
              </div>;
const subjects=<div className="row">
                  <div className="col-sm-10 col-md-11 col-lg-9"  >
                    <Subject data='הכנה לבגרות העיונית במדעי המחשב (java ו c#)' />
                    <Subject data='מבוא למדעי המחשב בשפת java ' />
                    <Subject data='מבני נתונים' />
                    <Subject data='מבני נתונים ומבוא לאלגוריתמים' />
                    <Subject data='הכנה למבחני מה"ט הנדסת תוכנה' />
                  </div>
                </div>

class Main extends Component
{
  render()
  {
    return(
        <div  id={styles.main} >
            <div  id={styles.blurred} ></div>
            <BottomQuete />
            <div className="container" id={styles.myContainer}>
              {title}
              {aboutMe}
              {subjects}
              <p> ניסיון בהגשה לבחינות גמר בקורסים 20441 20433 20407 של האוניבריסטה הפתוחה</p>
            </div>
        </div>
      );
  }
}

export default Main;
